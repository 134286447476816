import React from 'react';

const TermsAndConditionsContactForm: React.FC = () => {
    return (
        <div className="tac-modal">
            <div className="content">
                <div className="header">
                    <div className="text">お問い合わせフォームにおける個人情報の取り扱いについて</div>
                </div>

                <div className="text-container">
                    <div className='para'>
                        <div className="bold mb-15">１．組織の名称又は氏名</div>
                        <div className="indent-level-two">株式会社はばたーく</div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">２．個人情報保護管理者（若しくはその代理人）の氏名又は職名、所属及び連絡先</div>
                        <div className="indent-level-two">システム部 　尾崎 高太　 TEL:03-4530-0076</div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">３．個人情報の利用目的</div>
                        <div className="indent-level-two">
                            <span>・各種お問い合わせ対応のため</span>
                            <span>・弊社サービスのご案内の為</span>
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">４．個人情報の取り扱い業務の委託</div>
                        <div className="indent-level-two">
                            個人情報の取扱業務の全部または一部を外部に業務委託する場合があります。その際、弊社は、個人情報を適切に
                            保護できる管理体制を敷き実行していることを条件として委託先を厳選したうえで、機密保持契約を委託先と締
                            結し、お客様の個人情報を厳密に管理させます。
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">5．個人情報の第三者提供 </div>
                        <div className="indent-level-two">
                                弊社グループ会社サービスのご案内のため、会社名、氏名、メールアドレス、電話番号を電子メール添付(パス
                                ワード付)もしくは、弊社WEB管理ツールを通じ、弊社グループ会社へ提供いたします。
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">6．個人情報の開示等の請求</div>
                        <div className="indent-level-two">
                                お客様は、弊社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停
                                止または消去、第三者への提供の停止）に関して、当社問合わせ窓口に申し出ることができます。
                                その際、弊社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。
                                なお、個人情報に関する弊社問合わせ先は、次の通りです。
                                <span className="indent-level-one">
                                    株式会社はばたーく　個人情報問合せ窓口<br />
                                    東京都中央区日本橋本町4-8-15　ネオカワイビル5F<br />
                                    TEL: 03-4530-0076  メールアドレス: pmarkteam@haveatalk.jp<br />
                                    受付時間: 9:30～18:00(土・日曜日、祝日、年末年始、ゴールデンウィークを除く)
                                </span>
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">7．個人情報を提供されることの任意性について</div>
                        <div className="indent-level-two">
                            お客様がご自身の個人情報を弊社に提供されるか否かは、お客様のご判断によりますが、もしご提供されない場合
                            には、適切なサービスが提供できない場合がありますので予めご了承ください。
                        </div>
                    </div>

                    <div className="right-align">以上</div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditionsContactForm;