import './TermsAndConditions.css'
import React from 'react';

const TermsAndConditions: React.FC = () => {
    return (
        <div className="tac-modal">
            <div className="content">
                <div className="header">
                    <div className="text">個人情報保護方針</div>
                </div>
                <div className="text-container">
                    <div className="right-align">
                        制定年月日　2019年9月1日<br />
                        最終改正年月日　2021年4月1日<br />
                        株式会社はばたーく<br />
                        代表取締役 中村　和博
                    </div>
                    <p className='para'>
                        当社は、当社が取り扱う全ての個人情報の保護について、社会的使命を十分に認識し、本人の権利  
                        の保護、個人情報に関する法規制等を遵守します。また、以下に示す方針を具現化するための個人情 
                        報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動
                        等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言します。
                    </p>
                    
                    <div className='bold para'>
                        <p className="num">a)</p>
                        <p className="str">個人情報は、ITソリューションサービス、システム開発業務、システムエンジニアリング
                        サービス業務における当社の正当な事業遂行上並びに従業員の雇用、人事管理上必要な範囲に
                        限定して、取得・利用及び提供をし、特定された利用目的の達成に必要な範囲を超えた個人情
                        報の取扱い（目的外利用）を行いません。また、目的外利用を行わないための措置を講じます。</p>
                    </div>
                    
                    <div className='bold para'>
                        <p className="num">b)</p>
                        <p className="str">個人情報保護に関する法令、国が定める指針及びその他の規範を遵守致します。</p>
                    </div>
                    
                    <div className='bold para'>
                        <p className="num">c)</p>
                        <p className="str">個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講
                        じて防止すべく事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。
                        また、個人情報保護上、問題があると判断された場合には速やかに是正措置を講じます。</p>
                    </div>
                    
                    <div className='bold para'>
                        <p className="num">d)</p>
                        <p className="str">個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に、適切な対応をさせ
                        ていただきます。</p>
                    </div>

                    <div className='bold para'>
                        <p className="num">e)</p>
                        <p className="str">個人情報保護マネジメントシステムは、当社を取り巻く環境の変化を踏まえ、適時・適切に見直
                        してその改善を継続的に推進します。</p>
                    </div>

                    <div className="right-align">以上</div>

                    <div className='para'>
                        <span className="bold">【お問合せ窓口】</span><br />
                        個人情報保護方針に関するお問合せにつきましては、下記窓口で受付けております。
                    </div>

                    <div className="bordered-box"> 
                        株式会社はばたーく 　個人情報問合せ窓口<br />
                        東京都中央区日本橋本町4-8-15　ネオカワイビル<br />
                        TEL: 03-4530-0076  FAX: 03-4530-0002<br />
                        メールアドレス: <span className='highlight'>pmarkteam@haveatalk.jp</span><br />
                        受付時間: 9:30～18:00(土・日曜日、祝日、年末年始、ゴールデンウィークを除く)
                    </div>

                    <br />

                    <p className='para'>（個人情報に関する公表文）</p>

                    <div className='para'>
                        <div className="bold mb-15">１．組織の名称又は氏名</div>
                        <div className="indent-level-two">株式会社はばたーく</div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">２．個人情報保護管理者（若しくはその代理人）の氏名又は職名、所属及び連絡先</div>
                        <div className="indent-level-two">システム部 　尾崎 高太　 TEL:03-4530-0076</div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">３．個人情報の利用目的</div>
                        <div className="indent-level-two">
                            <span>・各種お問い合わせ対応のため</span>
                            <span>・弊社サービスのご案内の為</span>
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">４．個人情報の取り扱い業務の委託</div>
                        <div className="indent-level-two">
                            個人情報の取扱業務の全部または一部を外部に業務委託する場合があります。その際、弊社は、個人情報を適切に
                            保護できる管理体制を敷き実行していることを条件として委託先を厳選したうえで、機密保持契約を委託先と締
                            結し、お客様の個人情報を厳密に管理させます。
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">5．個人情報の第三者提供 </div>
                        <div className="indent-level-two">
                                弊社グループ会社サービスのご案内のため、会社名、氏名、メールアドレス、電話番号を電子メール添付(パス
                                ワード付)もしくは、弊社WEB管理ツールを通じ、弊社グループ会社へ提供いたします。
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">6．個人情報の開示等の請求</div>
                        <div className="indent-level-two">
                                お客様は、弊社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停
                                止または消去、第三者への提供の停止）に関して、当社問合わせ窓口に申し出ることができます。
                                その際、弊社はお客様ご本人を確認させていただいたうえで、合理的な期間内に対応いたします。
                                なお、個人情報に関する弊社問合わせ先は、次の通りです。
                                <span className="indent-level-one">
                                    株式会社はばたーく　個人情報問合せ窓口<br />
                                    東京都中央区日本橋本町4-8-15　ネオカワイビル5F<br />
                                    TEL: 03-4530-0076  メールアドレス: pmarkteam@haveatalk.jp<br />
                                    受付時間: 9:30～18:00(土・日曜日、祝日、年末年始、ゴールデンウィークを除く)
                                </span>
                        </div>
                    </div>

                    <div className='para'>
                        <div className="bold mb-15">7．個人情報を提供されることの任意性について</div>
                        <div className="indent-level-two">
                            お客様がご自身の個人情報を弊社に提供されるか否かは、お客様のご判断によりますが、もしご提供されない場合
                            には、適切なサービスが提供できない場合がありますので予めご了承ください。
                        </div>
                    </div>

                    <div className="right-align">以上</div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;